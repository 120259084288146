import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Mpi from 'assets/portfolio/mpi.jpg';
import Makalukhabar from 'assets/portfolio/makalukhabar.jpeg';
import Gpi from 'assets/portfolio/gpi.jpeg';
import Sodveloncon from 'assets/portfolio/sodveloncon.jpeg';
import Medical from 'assets/portfolio/medical.jpeg';
import Pts from 'assets/portfolio/pts.jpeg';
import plaispl from 'assets/portfolio/plaispl.jpeg';
import geetamata from 'assets/portfolio/geetamata.jpeg';
import lpshm from 'assets/portfolio/lpshm.jpeg';

const mock = [
  {
    image: Mpi,
    description: 'Web application and software development for MPI',
    title: 'Mahuregadi Polytechnic Institute(MPI),',
  },
  {
    image: Makalukhabar,
    description: 'One of the top news portal of Nepal',
    title: 'makalukhabar.com',
  },
  {
    image: Sodveloncon,
    description: 'IT partner for SODVELONCON 2019',
    title: 'SODVELONCON 2019',
  },
  {
    image: Gpi,
    description: 'Web application and software development for GPI',
    title: 'GORKHA POLYTECHNIC INSTITUTE (GPI)',
  },
  {
    image: Medical,
    description:
      'Mobile application development for medical entrance preparation',
    title: 'Medical Entrance Preparation App',
  },
  {
    image: Pts,
    description: 'Web application and software development for PTS',
    title: 'Panauti Technical School​ (PTS)',
  },
  {
    image: plaispl,
    description: 'Web app and software development for PLAISPL',
    title: 'Priceless Advice International Service (PLAISPL)',
  },
  {
    image: geetamata,
    description: 'Web development LMS software for school',
    title: 'Shree Geetamata Higher Secondary School',
  },
  {
    image: lpshm,
    description: 'Web development customized software for LPSHM',
    title: 'Le Professionnel School of Hotel Management (LPSHM)',
  },
];

const Main = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={'flex'}
                flexDirection={'column'}
              >
                <CardMedia
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 340, md: 400 },
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
                <Box component={CardContent}>
                  <Typography variant={'h6'} fontWeight={700} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant={'body2'} color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Main;
