export const react = [
  {
    title: 'JavaScript ES6 - ES12',
    topics: [
      {
        topicName: 'Modern JS Operator',
        description:
          'Destructuring Array & Object, Spread Operator, Rest Pattern & Parameters, Short Circuiting, The Nullish Coalescing  Operator, Optional Chaining & Manipulating strings in JavaScript',
      },
      {
        topicName: 'Advanced Array & Function Concepts in JS',
        description:
          'Higher Order Function & Callback Function, forEach, map, filter, reduce & find methods',
      },
      {
        topicName: 'Asynchronous JavaScript',
        description:
          'Make Real App to Simulate CallbackHell, Promises, Event Loop, async await, Parallel Promises & Promise Combinators',
      },
      {
        topicName: 'Modern JS & Functional Programming',
        description:
          'ES6 Modules, Module Pattern, CommonJS Modules, NPM, Parcel, Babel, Polyfilling & Declarative with Functional JS Principles',
      },
    ],
  },
  {
    title: 'Basic React',
    topics: [
      {
        topicName: 'Setting up JavaScript for React',
        description: 'Interacting with DOM nodes using JavaScript',
      },
      {
        topicName: 'Migration from JavaScript to React',
        description:
          'Using React top level API in single .html file without JSX',
      },
      {
        topicName: 'JSX for React',
        description: 'Using JSX with React in single .html file',
      },
      {
        topicName: 'Implementing First Component',
        description: 'Writing first React components',
      },
      {
        topicName: 'Class Based Components',
        description: 'Bread and butter of most legacy React Web Apps',
      },
    ],
  },
  {
    title: 'Intermediate React',
    topics: [
      {
        topicName: 'useState Hook',
        description: 'State in react using useState hook',
      },
      {
        topicName: 'useEffect Hook',
        description: 'Built in hook for React renders (and re-renders)',
      },
      {
        topicName: 'useRef Hook',
        description: 'A mutable object in React',
      },
      {
        topicName: 'React Portals',
        description:
          'Render children into a DOM node that exists outside the DOM hierarchy of the parent component.',
      },
      {
        topicName: 'Error Boundaries',
        description: 'Catching JavaScript errors anywhere in child components',
      },
      {
        topicName: 'Connecting to Database',
        description:
          'Sending http request and making CRUD operation to Database',
      },
    ],
  },
  {
    title: 'Advanced React',
    topics: [
      {
        topicName: 'useReducer Hook',
        description:
          'Managing multiple states in React App. An alternative to useState Hook.',
      },
      {
        topicName: 'useContext Hook',
        description: 'Easily pass data through your React App',
      },
      {
        topicName: 'useCallback Hook',
        description: 'Memorize functional depedency changes in your React App.',
      },
      {
        topicName: 'Component Composition',
        description: 'Replacement for prop drilling and Context API',
      },
      {
        topicName: 'useImperativeHandle Hook',
        description:
          'Customize your instance value in your React App that is exposed to parent components when using ref.',
      },
      {
        topicName: 'useDebugValue Hook',
        description:
          'Log information in the dev tool in an easier and smart fashion rather than just seeing the values of the hook of your React App.',
      },
    ],
  },
  {
    title: 'Real World React Design Patterns',
    topics: [
      {
        topicName: 'Context Module Functions',
        description:
          'Encapsulate a complex set of state changes into a utility function',
      },
      {
        topicName: 'Compound Components',
        description:
          'Utilize the power of React context with compound components',
      },
      {
        topicName: 'Layout Components',
        description: 'Arranging multiple component',
      },
      {
        topicName: 'Container Components',
        description: 'Data loading and Data Management for Child Components',
      },
      {
        topicName: 'Prop Collections and Getters',
        description: 'Using useLayoutEffect instead of useEffect',
      },
      {
        topicName: 'State Reducer',
        description: 'Real world project with React Hooks',
      },
      {
        topicName: 'Control Props',
        description: 'Control a component UI state from the parent component.',
      },
      {
        topicName: 'Higher Order Component',
        description: 'Component returning another component',
      },
    ],
  },
  {
    title: 'Beyond React',
    topics: [
      {
        topicName: 'TypeScript',
        subtitle: 'New & most demanded industry skill with React',
        description:
          'Compile-time Validation & Better Legibility, Easier Maintenance For Your App',
      },
      {
        topicName: 'Advanced Redux & Redux Toolkit with CSS Modules',
        subtitle: 'New & most demanded industry skill with React',
        description: 'Centralizing complex application state in React',
      },
      {
        topicName: 'Redux Thunk, Redux Promise, Redux Observable & Redux Saga',
        subtitle: 'New & most demanded industry skill with React',
        description: 'Middleware libraries to manage async action',
      },
      {
        topicName: 'React Testing Library with Jest',
        subtitle: 'New & most demanded industry skill with React',
        description:
          'Functional testing without relying on their implementation details of your components in React App',
      },
      {
        topicName: 'React Router DOM V6 with Emotion CSS',
        subtitle: 'New & most demanded industry skill with React',
        description: 'Standard library for routing in React',
      },
      {
        topicName: 'GraphQL',
        subtitle: 'New & most demanded industry skill with React',
        description: 'Query your API data in better and efficient way',
      },

      {
        topicName: 'Styled Components for Styling React Components',
        subtitle: 'New & most demanded industry skill with React',
        description:
          'CSS-in-JS styling framework that uses tagged template literals in JavaScript and the power of CSS to provide a platform that allows you to write actual CSS to style React components.',
      },
      {
        topicName: 'Introduction to NextJS & Remix',
        subtitle: 'Most popular ReactJS frameworks in 2022.',
      },
    ],
  },
  {
    title: 'React Capstone Project',
    topics: [
      {
        topicName: 'Build Realworld React Project',
        description:
          'This project will include all the concepts we have covered so far. In addition we will cover Express, MongoDB Atlas & NodeJS. This project is always created from scratch to use cutting edge tech and patterns.',
      },
      {
        topicName: 'Deploying your App',
        description:
          'Deploy your optimized and production ready app to public domain.',
      },
    ],
  },
];

export const courseLists = [
  {
    coursename: 'react',
    image:
      'https://res.cloudinary.com/gorakhjoshi-com/image/upload/v1641979502/gorakhjoshi.com/reactjs-gorakh_cputaj.png',
    description: 'Duration: 2 Months',
    title: 'Become a FrontEnd Developer with ReactJS',
  },
  {
    coursename: 'Flutter',
    image:
      'https: //res.cloudinary.com/gorakhjoshi-com/image/upload/v1641979502/gorakhjoshi.com/reactjs-gorakh_cputaj.png',
    description: 'Duration: 2 Months',
    title: 'Build iOS and Android App with Flutter & Dart',
  },
  {
    coursename: 'Kubernetes with microservices',
    image:
      'https://res.cloudinary.com/gorakhjoshi-com/image/upload/v1674466042/devops_wab22f.jpg',
    description: 'Duration: 2 Months',
    title: 'Become an expert at Kubernetes and microservices',
  },
];

export const flutter = [
  {
    title: 'Flutter Essentials',
    topics: [
      {
        topicName: 'Flutter Concept and Introduction',
        description: 'Know about flutter along wuth features and advantages',
      },
      {
        topicName: 'Flutter Installation',
        description:
          'install Flutter SDK and its requirement in a windows and macbook.',
      },
      {
        topicName: 'Creating Simple Application in Android Studio',
        description:
          'create a simple Flutter application to understand the basics of creating a flutter application in the Android Studio.',
      },
      {
        topicName: 'Architecture Application',
        description: ' discuss the architecture of the Flutter framework.',
      },
      {
        topicName: 'Introduction to dart',
        description: 'dart concepts and others stuffs related to dart',
      },
    ],
  },
  {
    title: 'Flutter Basics',
    topics: [
      {
        topicName: 'Introduction to Widgets',
        description:
          'understand the actual concept behind creating the widgets and the different type of widgets available in Flutter framework',
      },
      {
        topicName: 'Introduction to layouts',
        description: 'learn the Flutter layout concept',
      },
      {
        topicName: 'Introduction to Gestures',
        description:
          'allows us to interact with the mobile app (or any touch-based device). ',
      },
      {
        topicName: 'Introduction to responsive UI design',
        description: 'Learn how to design flutter Responsive UI',
      },
    ],
  },
  {
    title: 'Intermediate Flutter',
    topics: [
      {
        topicName: 'Basic state management concepts',
        description: 'Learn about state and its management in app',
      },
      {
        topicName: 'Animation',
        description:
          'know how to provides a simple and intuitive framework to develop all types of animations.',
      },
      {
        topicName: ' Writing Android and Ios Specific Code',
        description:
          'provides a general framework to access platform specific feature',
      },
      {
        topicName: 'Introduction to packages',
        description:
          'Know about packages and its implementation in application',
      },
      {
        topicName: 'Accessing REST API',
        description: 'Development of REST based mobile applications.',
      },
      {
        topicName: 'Database and its implementation in application',
        description:
          'Sending http request and making CRUD operation to Database',
      },

      {
        topicName: 'Deploy Apps in Play Store and App Store',
        description: 'Know how to deploy your app in play and app store',
      },
    ],
  },
  {
    title: 'Advanced Flutter',
    topics: [
      {
        topicName: 'Folder Structures',
        description:
          'Know to structure project Folders in Proper and Managed Structures ',
      },
      {
        topicName: 'Architecture and implementation',
        description:
          'Concept about Architecture and implementation of one Architecture among available architect',
      },
      {
        topicName: 'Platform specific code',
        description:
          'learn to use your platform-specific code in multiple Flutter apps',
      },
      {
        topicName: 'Error Handling',
        description: 'Learn about error handling in real application',
      },
    ],
  },
];

export const uiux = [
  {
    title: 'Introduction',
    topics: [
      {
        topicName: 'What you will be learning',
        description: 'Get to know what you’ll be learning in this course.',
      },

      {
        topicName: 'What is product design?',
        description: 'Learn about product design and UI/UX.',
      },
    ],
  },
  {
    title: 'UX Research and Design',
    topics: [
      {
        topicName: 'UX Design and Research',
        description:
          'Understand the value and learn how to conduct UX Research.',
      },
      {
        topicName: 'Inspiration & Ideation',
        description:
          'Understand how to ideate on a user problem with a series of practical techniques',
      },
      {
        topicName: 'Competitive Analysis',
        description: 'Understand about competitors and analyze competitors. ',
      },
      {
        topicName: 'Personas',
        description: 'Make user personas for better product designing',
      },
    ],
  },
  {
    title: 'Business and Product Strategy',
    topics: [
      {
        topicName: 'Project Goals',
        description: 'Learn about project goals and purpose.',
      },
      {
        topicName: 'Project Roadmap',
        description:
          'Learn the value of mapping the content of a product both globally and locally.',
      },
      {
        topicName: 'MVP Features',
        description: 'Learn how to list out the MVP features of the product.',
      },
    ],
  },
  {
    title: 'Information Architecture and User Interaction Design',
    topics: [
      {
        topicName: 'Information architecture',
        description: 'Learn about information architecture in product design.',
      },
      {
        topicName: 'User Interaction Design',
        description:
          'Learn what user interaction design is and how it helps for better user experience.',
      },
      {
        topicName: 'Site Map',
        description: 'Learn how to make a site map of the product.',
      },
    ],
  },

  {
    title: 'User Interface Design',
    topics: [
      {
        topicName: 'User Interface Design',
        description:
          'Master creating Color palettes, Typography, Grid Systems and all styles in Figma',
      },
      {
        topicName: 'Components',
        description: 'Master how to make components and use components.',
      },
      {
        topicName: 'Design system',
        description:
          'Master how to make your own design system to make your work efficient.',
      },
      {
        topicName: 'User Flow',
        description:
          'Master user flow diagramming in Figma to help inform your design.',
      },
      {
        topicName: 'Wireframing & sketching',
        description:
          'Learn the value of rapidly ideating and wireframing to test your design.',
      },
    ],
  },
  {
    title: 'Designing the MVP',
    topics: [
      {
        topicName: 'MVP( Minimum Viable Product)',
        description:
          'Take all of the research, flows, and components you’ve created and turn them into high-fidelity screen designs of your product',
      },
    ],
  },
  {
    title: 'Prototyping',
    topics: [
      {
        topicName: 'Animation & Prototyping',
        description:
          'Create animations of your hi-fidelity mockups using smart-animate in Figma.',
      },
    ],
  },
  {
    title: 'Final Project',
    topics: [
      {
        topicName: 'Portfolio & Case study',
        description:
          'Learn how to create your design portfolio and product case study.',
      },
    ],
  },
];

export const mern = [
  {
    title: 'Introduction To Typescript',
    topics: [
      {
        topicName: 'Typescript Concept and Introduction',
        description:
          'Introduction on typescript. Learn about its importance and uses.',
      },
      {
        topicName: 'Typescript Basic Concept',
        description:
          'Learning interface, types, generic and other basics of typescript.',
      },
      {
        topicName: 'Typescript Advance Concept',
        description:
          'Know about typescript utlity types. Learn Pick, Partial, Omit, Record and many more advance features',
      },
    ],
  },
  {
    title: 'React & Typescript',
    topics: [
      {
        topicName: 'Introduction on React & Typescript',
        description:
          'Understand the concept of React & Typescript. Intialize and React app on typescript and learn its file structures.',
      },
      {
        topicName: 'Implementation of Typescript in React',
        description:
          'Learn about typescript implementation on React component, props, functions, states and many more.',
      },
      {
        topicName: 'Create simple React + Typescript app.',
        description: 'We will create simple React application on Typescript.',
      },
    ],
  },
  {
    title: 'React & Redux Toolkit',
    topics: [
      {
        topicName: 'Basic state management concepts using redux',
        description: 'Learn about state and its management in react app',
      },
      {
        topicName: 'Introduction on Redux Toolkit',
        description:
          'Learn about redux store and uses of toolkit in state management.',
      },
      {
        topicName: 'Redux Toolkit on Typescript',
        description:
          'Learn about redux toolkit integration on React using typescript.',
      },
      {
        topicName: 'Create simple app using React + Redux tookit + Typescript',
        description:
          'We will create simple react app that features basic features of Redux toolkit on typescript',
      },
      {
        topicName: 'Implement asynchronous call on redux toolkit.',
        description:
          'Know about asynchronous apis call in redux toolkit to utilize various asynchronous states.',
      },
    ],
  },
  {
    title: 'React Testing',
    topics: [
      {
        topicName: 'Introduction on Test Driven Development(TDD).',
        description: 'Introduction to TDD in software development',
      },
      {
        topicName: 'Introduction to RTL & Jest',
        description: 'Learn about testing libary like Jest and RTL',
      },
      {
        topicName: 'RTL & Jest basic implementation',
        description:
          'Learn RTL and Jest basic testing concepts and write our first test.',
      },
      {
        topicName:
          'Create an app using React and Redux Toolkit on typescript following TDD approach',
        description:
          'We will create and app using everthing we have learned and implement TDD on its UI and Redux',
      },
    ],
  },

  {
    title: 'Setting up Nodejs',
    topics: [
      {
        topicName: 'Setting up Node.js environment.',
        // description: 'Introduction to TDD in software development',
      },
      {
        topicName: 'Global Object in Node.js',
        // description: 'Learn about testing libary like Jest and RTL',
      },
      {
        topicName: 'Synchronous vs Asynchronous Code',
        // description:
        //   'Learn RTL and Jest basic testing concepts and write our first test.',
      },
      {
        topicName: 'Blocking vs non-blocking code',
        // description:
        //   'We will create and app using everthing we have learned and implement TDD on its UI and Redux',
      },
      {
        topicName: 'The Event Loop',
      },
      {
        topicName: 'Processes and Threads',
      },
      {
        topicName: 'Test Driven Development',
      },
    ],
  },
  {
    title: 'Basics of Node.js',
    topics: [
      {
        topicName: 'Modules in Node.js.',
      },
      {
        topicName: 'Creating our first web server',
      },
      {
        topicName: 'Using NPM and installing nodemon',
      },
      {
        topicName: 'Cross Origin Resource Sharing (CORS)',
      },
      {
        topicName: 'Connecting Node.js with React',
      },
    ],
  },
  {
    title: 'Express with Node.js',
    topics: [
      {
        topicName: 'MVC Architecture',
      },
      {
        topicName: 'Creating middlewares',
      },
      {
        topicName: 'Express Routers',
      },
      {
        topicName: 'Creating RESTful APIs',
      },
      {
        topicName: 'Testing API Endpoints',
      },
      {
        topicName: 'Basic CRUD Operation with React',
      },
    ],
  },
  {
    title: 'Mongoose and MondoDB with MongoDB Atlas',
    topics: [
      {
        topicName: 'Setting up MongoDB Atlas',
      },
      {
        topicName: 'Creating models and schema with mongoose',
      },
      {
        topicName: 'Basic MongoDB, Express, React and Node.js (MERN) app',
      },
    ],
  },
  {
    title: 'Security and Authentication with Node.js',
    topics: [
      {
        topicName: 'Threats and Attacks in Node.js',
      },
      {
        topicName: 'Encrypted connections',
      },
      {
        topicName: 'Authentication vs Authorization',
      },
      {
        topicName: 'OAuth',
      },
      {
        topicName: 'Helmet.js',
      },
      {
        topicName: 'Passport.js',
      },
      {
        topicName: 'Dotenv',
      },
    ],
  },
  {
    title: 'Sockets and GraphQL',
    topics: [
      {
        topicName: 'Creating Socket server',
      },
      {
        topicName: 'Listening and Broadcasting events',
      },
      {
        topicName: 'GraphQL vs Restful API',
      },
      {
        topicName: 'Apollo Server with Node.js',
      },
      {
        topicName: 'Modularizing GraphQL Schema',
      },
    ],
  },
  {
    title: 'Building CICD Pipeline & Deploying Real-world MERN Apps in AWS',
    topics: [
      {
        topicName: 'Capstone MERN Project',
      },
      {
        topicName: 'Elastic Beanstalk',
      },
      {
        topicName: 'CodePipeline with AWS',
      },
      {
        topicName: 'Setting up GitHub Actions',
      },
      {
        topicName: 'AWS CodeBuild',
      },
    ],
  },
];

export const devops = [
  {
    title: 'Kubernetes: Microservices',
    topics: [
      {
        topicName: 'Introduction',
        description: 'Introduction to this course',
      },
      {
        topicName: 'What you shoud know',
        description: 'What are the knowledge expected to have ?',
      },
    ],
  },
  {
    title: 'Introduction to Microservices',
    topics: [
      {
        topicName: 'Microservices 101',
        description: 'Introduction to microservices',
      },
      {
        topicName: 'Benefits of microservices architecture',
        description:
          'Benefits of microservices architecture. Why you should use it  ',
      },
      {
        topicName: 'Common microservices pattern',
        description: 'How this architecture is used in the industry',
      },
    ],
  },
  {
    title: 'Kubernetes',
    topics: [
      {
        topicName: 'Kubernetes 101',
        description: 'What is kubernetes',
      },
      {
        topicName: 'Benefits of Kubernetes',
        description: 'Why and who should use kubernetes?',
      },
    ],
  },
  {
    title: 'Advanced Kubernetes',
    topics: [
      {
        topicName: 'Structures ',
        description: 'Know the structure ',
      },
      {
        topicName: 'Architecture and implementatio',
        description:
          'Concept about Architecture and implementation of one Architecture among available architect',
      },
      {
        topicName: 'Components of Kubernetes',
        description: 'Know in detail about the component of kubernetes',
      },
      {
        topicName: 'Kubernetes Pods',
        description: 'Know about pods',
      },
      {
        topicName: 'Kubernetes Volumes',
        description: 'Learn about how volumes are attached and used',
      },
      {
        topicName: 'Kubernetes Deployment',
        description: 'Learn about Deployment',
      },
      {
        topicName: 'Kubernetes Services',
        description: 'What are services and how you should use this?',
      },
      {
        topicName: 'Kubernetes Statefulsets',
        description: 'What is statefulsets',
      },
    ],
  },
  {
    title: 'Microservices and Kubernetes',
    topics: [
      {
        topicName: 'Microservice Deployment to kubernetes ',
        description: 'Deployment and real world implementations',
      },
      {
        topicName: 'Cronjobs ',
        description: 'how to integrate and run cronjobs',
      },
      {
        topicName: 'Consumers & jobs ',
        description: 'How to run jobs and consumers ',
      },
      {
        topicName: 'Ingress Controller ',
        description: 'What is Ingress Controller and how it should work? ',
      },
    ],
  },
  {
    title: 'Wrapping up',
    topics: [
      {
        topicName: 'Resource Management',
        description: 'Finish Up by managing resource',
      },
      {
        topicName: 'Security',
        description: 'Let us talk about security and its implementation',
      },
    ],
  },
];

export const coming_soon = [
  {
    title: 'BitpointX Learning',
    topics: [],
  },
];
