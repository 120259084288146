import React, { useState } from 'react';
// import Box from '@mui/material/Box';

import { PopupBox } from './components';

// import Container from 'components/Container';

const PopupWithImage = () => {
  const [open, setOpen] = useState(true);

  return <PopupBox open={open} onClose={() => setOpen(false)} />;
};

export default PopupWithImage;
