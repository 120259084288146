/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import webDev from 'assets/bitpoint/webDev.webp';

const CaseStudy1 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={4}
      bgcolor={
        theme.palette.mode === 'light' ? colors.blue[50] : colors.blue[900]
      }
      data-aos={'fade-up'}
    >
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              xs={12}
              sx={{
                '& .lazy-load-image-background.lazy-load-image-loaded': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src={webDev}
                alt="..."
                effect="blur"
                borderRadius={4}
                maxWidth={1}
                maxHeight={400}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                Modern Responsive Design
              </Typography>
              <Typography component={'p'}>
                Bitpoint implement professional development service with
                consistent and responsive design for your web application in all
                devices
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                Free support for first 12 months
              </Typography>
              <Typography component={'p'}>
                We provide free support for using any of our services for first
                6 months. Some of our special services are also accessable for
                lifetime.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            height={1}
          >
            <Box>
              <Typography variant={'h4'} fontWeight={700} gutterBottom>
                Web Application Development
              </Typography>
              <Typography
                color={
                  theme.palette.mode === 'light'
                    ? 'text.secondary'
                    : 'text.primary'
                }
                sx={{ pb: 2 }}
              >
                Growing user expectations propel ongoing changes to technology,
                and have forced brands to deliver digital experiences that are
                not only fun, and intuitive but engaging as well; making it more
                important than ever for brands to be accessible everywhere, in
                real-time and on both desktop and mobile devices.
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 0 }}
              boxShadow={0}
              borderRadius={4}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={
                    'https://www.makalukhabar.com/wp-content/uploads/2021/08/ma-1.jpg'
                  }
                  alt="..."
                  maxWidth={80}
                  marginBottom={2}
                  // sx={{
                  //   filter:
                  //     theme.palette.mode === 'dark'
                  //       ? 'brightness(0) invert(0.7)'
                  //       : 'none',
                  // }}
                />
                <Typography component={'p'}>
                  It’s been over a year since Bitpoint completed the extension,
                  and it’s been performing admirably since then. Great work!
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={'subtitle1'} sx={{ fontWeight: 700 }}>
                    CR Basnet
                  </Typography>
                  <Typography color="text.secondary">
                    Makalu Khabar (makalukhabar.com)
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy1;
