import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  CaseStudy1,
  CaseStudy2,
  CaseStudy3,
  Hero,
  Contact,
  Courses,
  Process,
  Featured,
  PopupWithImage,
} from './components';

const Bitpoint = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <PopupWithImage />
      <Container>
        <Hero />
      </Container>
      <Container>
        <CaseStudy1 />
      </Container>
      <Container paddingY={'0 !important'}>
        <CaseStudy2 />
      </Container>
      <Container>
        <CaseStudy3 />
      </Container>
      <Container maxWidth={{ sm: 1, md: 1920 }}>
        <Box marginBottom={4}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'medium',
              letterSpacing: 3,
            }}
            gutterBottom
            color={'secondary'}
            align={'center'}
          >
            Welcome to BitpointX Learnig
          </Typography>
          <Typography
            variant="h4"
            align={'center'}
            data-aos={'fade-up'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
            Grow your professional skills
          </Typography>
          <Typography
            variant="h6"
            align={'center'}
            color={'text.secondary'}
            data-aos={'fade-up'}
          >
            Learn From Indestry Expert Developers
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            justifyContent={'center'}
            marginTop={2}
          >
            <Button
              href="https://l5blrp4knzx.typeform.com/to/xQVdhUVV"
              target="_blank"
              variant="contained"
              color="primary"
              size="large"
              fullWidth={isMd ? false : true}
              endIcon={
                <Box
                  component={'svg'}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width={24}
                  height={24}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </Box>
              }
            >
              Book Your Seat
            </Button>
            {/* <Box
            component={Button}
            variant="outlined"
            color="primary"
            size="large"
            marginTop={{ xs: 2, sm: 0 }}
            marginLeft={{ sm: 2 }}
            fullWidth={isMd ? false : true}
          >
            Book Your Seat
          </Box> */}
          </Box>
        </Box>
        <Featured />
        <Courses />
      </Container>
      <Container>
        <Process />
      </Container>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Container>
          <Contact />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default Bitpoint;
