import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import Container from '../../components/Container';
import { update } from '../../store/update';

const CtaSimpleCentered = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Container sx={{ pb: 2, mt: -10 }}>
      <Box>
        <Typography
          variant="h4"
          color="text.primary"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          {/* Get started with theFront today */}
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={{ fontWeight: 400 }}
          align={'center'}
        >
          {/* Build a beautiful, modern website with flexible, fully customizable,
          atomic MUI components. */}
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'flex-start' }}
          justifyContent={'center'}
        >
          <Box marginTop={{ xs: 2, sm: 0 }} width={{ xs: '100%', md: 'auto' }}>
            {/* <Button
              component={'a'}
              variant='contained'
              color='text'
              size='large'
              fullWidth={isMd ? false : true}
            > */}
            {/* </Button> */}
          </Box>
          <Box
            marginTop={{ xs: 2, sm: 0 }}
            marginLeft={{ sm: 2 }}
            width={{ xs: '100%', md: 'auto' }}
          >
            <Button
              component={'a'}
              href="https://l5blrp4knzx.typeform.com/to/xQVdhUVV"
              variant="contained"
              color="secondary"
              size="large"
              fullWidth={isMd ? false : true}
            >
              ENROLL NOW
            </Button>
          </Box>
          <Box
            marginTop={{ xs: 2, sm: 0 }}
            marginLeft={{ sm: 2 }}
            marginRight={{ sm: 2 }}
            width={{ xs: '100%', md: 'auto' }}
          >
            <Button
              component={Link}
              to={'/'}
              variant="contained"
              color="primary"
              size="large"
              fullWidth={isMd ? false : true}
            >
              Go Home
            </Button>
          </Box>
          {/* <Button
            component={Link}
            to={`/`}
            variant='contained'
            color='primary'
            size='large'
            fullWidth={isMd ? false : true}
          >
            Go Home
          </Button> */}
        </Box>
        <Typography align="center" sx={{ pt: '1em' }}>
          Updated on {update.date}
        </Typography>
      </Box>
    </Container>
  );
};

export default CtaSimpleCentered;
