import React from 'react';

import {
  Bitpoint as BitpointMain,
  About as AboutView,
  PortfolioGrid as PortfolioGridView,
  Faq as FaqView,
  Contact as ContactView,
  WebDevelopment as WebDevelopmentView,
  CareerOpening as CareerOpeningView,
  MobileApp as MobileAppView,
  ListWithVerticalLine,
  NotFound,
} from 'views';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <BitpointMain {...params} />,
  },
  {
    path: '/about',
    renderer: (params = {}) => <AboutView {...params} />,
  },
  {
    path: '/our-portfolio',
    renderer: (params = {}) => <PortfolioGridView {...params} />,
  },

  {
    path: '/faq',
    renderer: (params = {}) => <FaqView {...params} />,
  },
  {
    path: '/contact',
    renderer: (params = {}) => <ContactView {...params} />,
  },
  {
    path: '/career-listing',
    renderer: (params = {}) => <CareerOpeningView {...params} />,
  },
  {
    path: '/web-development',
    renderer: (params = {}) => <WebDevelopmentView {...params} />,
  },
  {
    path: '/app-development',
    renderer: (params = {}) => <MobileAppView {...params} />,
  },
  {
    path: '/:coursename',
    renderer: (params = {}) => <ListWithVerticalLine {...params} />,
  },
  {
    path: '/not-found',
    renderer: (params = {}) => <NotFound {...params} />,
  },
];

export default routes;
