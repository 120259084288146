import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const mock = [
  {
    title: 'Custom Application Development',
    subtitle:
      'We build custom applications to help companies save smarter, grow faster, serve better; through automation, business process management, integrated systems to create unique advantages for the business. We use:',
  },
  {
    title: 'Content Management System',
    subtitle:
      'We anonymize your project brief and send it to the service providers who meet your requirements.',
  },
  {
    title: 'Content Management System',
    subtitle:
      'Every website is unique, and so is the Content Management System (CMS) it needs. We understand website build, underlying technology, integration with enterprise applications.',
  },
  {
    title: 'E-commerce',
    subtitle:
      'E-commerce starts with your store, but needs to extend and work seamlessly with CRM, Business Intelligence (BI) Tools, Marketing Automation, A/B Testing, Re-marketing, Accounting/ERP, Third-party APIs (Inventory, PoS, Payments, Shipping) to enable you to build a successful business..',
  },
  {
    title: 'API Development',
    subtitle:
      'We build strong, secure, scalable Application Programming Interface (API) to power the world of Service Oriented Architecture (SOA) to tap the unlimited world of opportunities which the connected application brings forth with it.',
  },
  {
    title: 'Legacy Application Migration',
    subtitle:
      'We migrate your old software to the new digital technology stack to ensure interoperability, scalability and extendability in the fast-evolving technology landscape.',
  },
  {
    title: 'Customer Relationship Management',
    subtitle:
      'Managing your existing customers and tracking new opportunities is critical for business growth. We help you engage every individual who touches your brand-customer or prospect and integrate marketing automation to ensure that contextual touch-points happen without manual intervention.',
  },
  {
    title: 'Intranets/Extranets',
    subtitle:
      'We create communication and collaboration platform for employees, customers, suppliers and partners of your business. We understand that implementation and adoption of such systems are far more difficult than the technical development.',
  },
];

const Faq = () => {
  return (
    <Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                <Typography variant={'h6'} gutterBottom fontWeight={500}>
                  {item.title}
                </Typography>
              </Box>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Faq;
