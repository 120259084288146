import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Web Development'}
          items={[
            {
              title: 'How long will it take to get a new website?',
              subtitle:
                'On average, we aspire for a six to eight week turnaround, but the speed of the project is established by each client. How much involvement you can offer at the earliest phases, your availability with comments, how quickly the content is available – all this influences the pace of completion. The functionality demands may also play a factor— more complicated sites will take more time to develop.',
            },
            {
              title: 'Do I have to be local to work with you?',
              subtitle:
                'Nope! We collaborate with clients all across the globe. Our complete staff works remotely, enabling us to locate the exact best team for our firm.',
            },
            {
              title:
                'Will I be able to update the site myself when it’s finished?',
              subtitle:
                'Yes! We enjoy it when our clients take ownership of their website and learn to maintain it on their own. We’ll present you with a collection of detailed video lessons to assist you learn your way around, as well provide you with a training session to help start you on your way. (Note: We love to address your queries!).',
            },
            {
              title: 'Will you maintain my site for me?',
              subtitle:
                'We can! We offer on-going assistance for many of our clients.',
            },
            {
              title: 'How much input do I have in the process?',
              subtitle:
                'A lot! Your opinion and feedback is vital to this process. We’ll start with a lot of inquiries about your requirements, your likes, your desires and work with you to design exactly the appropriate appearance and functionality.',
            },
            {
              title: 'Who writes the content for the site?',
              subtitle:
                'Usually that is you. You are the authority on your business, therefore it’s typically best if it comes from you. If you need support, we can suggest content creators who can help clean up or develop unique material for you at an extra expense.',
            },
          ]}
        />
      </Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Mobile App Development'}
          items={[
            {
              title:
                'I am a non-techy person and wants to build an app. How can you help me to build the app?',
              subtitle:
                'Just send us an email with what you are thinking, and our sales staff will assist you to explain needs and flow of the app based on the requirement; this will be followed up throughout the construction of an app.',
            },
            {
              title:
                'What is the difference between Native App development vs Cross-platform development?',
              subtitle:
                'In native application development, we utilize default language and IDE for both Android and iOS, i.e. JAVA/Kotlin with Android Studio for Android and Objective C, Swift with Xcode for iOS. Cross-platform offers a framework in which development is done once and can be used to publish the software on iOS, Android, and Desktop platforms.',
            },
            {
              title: 'Can you provide a ballpark cost for the project?',
              subtitle:
                'Yes, we will present you with a reasonable quote as soon as possible; generally after initial conversation on phone. Having an approximate estimate of the projected expenditures enables you to assess if the proposal is financially viable or not. Our estimates are educated predictions based on our expertise, so please remember that the real final cost may rise or drop after examining your needs in more detail.',
            },
            {
              title: 'Do you have in house design team?',
              subtitle:
                'Yes, we have in house design team that are professional in branding, UI & UX designing.',
            },
            {
              title: 'How can I track the progress throughout the project?',
              subtitle:
                'We will offer you access to project management platform that we use along with Phone number, email and Virtual Meeting ID to follow the progress throughout the project.',
            },
            {
              title:
                'Would you take care of the submission of the app in the google play store or app store?',
              subtitle:
                'Yes, submission of application in the app store and play store is free service thus we will supply it.',
            },
          ]}
        />
      </Box>
      <Box>
        <FaqGroupItem
          title={'BitpointX Learning'}
          items={[
            {
              title: 'Why should I choose your Live courses for learning?',
              subtitle:
                'To promote industry standard experience, live courses are the most equivalent to classroom programs taught by expert developers. Students who choose this mode will receive all of the benefits of a classroom program, including personalized attention, classes on a set schedule, a specific focus on hands-on coding, placement assistance, special Doubt classes and problem solving classes, and much more.',
            },
            {
              title: 'What is the duration of each course?',
              subtitle:
                'Each course will take between 40 and 60 hours to complete.',
            },
            {
              title: 'Will there be any projects covered in the course?',
              subtitle:
                'In each session, we will have around 4-5 projects, plus one large capstone project. For further information, please see the course curriculum.',
            },
            {
              title: 'How can I communicate with instructor?',
              subtitle:
                'You can communicate with instructor via Discussion forum in our LMS. You can personally chat with instructor via our LMS. You can communicate with your other colleagues via group chat feature built in our LMS.',
            },
            {
              title:
                'I am new to the World of Coding, which course is best for me?',
              subtitle:
                'We recommend that you speak with one of our counsellors in order to enroll in the most appropriate course for your profile. Please contact us at 01-5909069 (Nepal) or +61424579678. (Australia)',
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
