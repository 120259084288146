const pages = {
  landings: [
    {
      title: 'About Us',
      href: '/about',
    },
    {
      title: 'Our Portfolio',
      href: '/our-portfolio',
    },
    {
      title: 'FAQ',
      href: '/faq',
    },
    {
      title: 'Contact Us',
      href: '/contact',
    },
  ],
  company: [
    {
      title: 'Web Development',
      href: '/web-development',
    },
    {
      title: 'Mobile App Development',
      href: '/app-development',
    },
  ],
  secondary: [
    {
      title: 'Job Opening',
      href: '/career-listing',
    },
  ],
  learning: [
    {
      title: 'React',
      href: '/react',
    },
    {
      title: 'Flutter',
      href: '/mobile_app_development_with_flutter',
    },
    {
      title: 'UI/UX',
      href: '/uiux',
    },
  ],
};

export default pages;
