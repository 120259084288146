/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const Map = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Box marginBottom={1}>
          <Typography
            variant="h4"
            align="center"
            sx={{
              fontWeight: 700,
            }}
          >
            Get in touch
          </Typography>
        </Box>
        <Box>
          <Typography align="center" variant="h6" color={'text.secondary'}>
            We'd love to talk about how we can help you.
          </Typography>
        </Box>
      </Box>
      <Box marginY={3}>
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          title="map"
          marginHeight={0}
          marginWidth={0}
          scrolling="no"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14129.296276728726!2d85.3330132!3d27.7072788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5028c4b48d4782b5!2sBitpoint%20Pvt.%20Ltd!5e0!3m2!1sen!2snp!4v1634497261425!5m2!1sen!2snp"
          style={{
            minHeight: 300,
            borderRadius: 8,
            filter:
              theme.palette.mode === 'dark'
                ? 'grayscale(0.5) opacity(0.7)'
                : 'none',
          }}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            align="center"
            variant={'body1'}
            gutterBottom
            sx={{ fontWeight: 'medium' }}
          >
            Call us:
          </Typography>
          <Typography align="center" variant={'subtitle1'}>
            01-5909069
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            align="center"
            variant={'body1'}
            gutterBottom
            sx={{ fontWeight: 'medium' }}
          >
            Email us:
          </Typography>
          <Typography align="center" variant={'subtitle1'}>
            info@bitpointnepal.com.np
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant={'body1'}
            gutterBottom
            sx={{ fontWeight: 'medium' }}
          >
            Address:
          </Typography>
          <Typography align="center" variant={'subtitle1'}>
            Maitidevi Marg, Kathmandu, Nepal
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Map;
