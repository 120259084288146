import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

import reactBackground from 'assets/courses/reactBackground.webp';
// import MERNBackground from 'assets/courses/MERNBackground.webp';
import uiux from 'assets/portfolio/uiux.jpg';
import flutterBackground from 'assets/courses/flutterBackground.webp';
// import pythonBackground from 'assets/courses/pythonBackground.webp';

import { Link } from 'react-router-dom';

const mock = [
  {
    price: 'Free Internship',
    media: reactBackground,
    title: 'Advanced React With ES6+',
    link: '/react',

    tutor: 'Become a Front End Developer',
    users: [
      // 'https://assets.maccarianagency.com/avatars/img1.jpg',
      // 'https://assets.maccarianagency.com/avatars/img2.jpg',
      // 'https://assets.maccarianagency.com/avatars/img3.jpg',
      // 'https://assets.maccarianagency.com/avatars/img4.jpg',
      // 'https://assets.maccarianagency.com/avatars/img5.jpg',
    ],
  },
  // {
  //   price: 'Free Internship',
  //   media: MERNBackground,
  //   title: 'MERN Stack Front To Back',
  //   tutor: 'Become a Full Stack Developer',
  //   link: '/',

  //   users: [
  //     // 'https://assets.maccarianagency.com/avatars/img1.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img2.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img3.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img4.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img5.jpg',
  //   ],
  // },
  {
    price: 'Free Internship',
    media: flutterBackground,
    link: '/mobile_app_development_with_flutter',
    title: 'Flutter & Dart',
    tutor: 'Become a Mobile App Developer',
    users: [
      // 'https://assets.maccarianagency.com/avatars/img1.jpg',
      // 'https://assets.maccarianagency.com/avatars/img2.jpg',
      // 'https://assets.maccarianagency.com/avatars/img3.jpg',
      // 'https://assets.maccarianagency.com/avatars/img4.jpg',
      // 'https://assets.maccarianagency.com/avatars/img5.jpg',
    ],
  },
  // {
  //   price: 'Free Internship',
  //   media: pythonBackground,
  //   title: 'Python & Django',
  //   tutor: 'Become a Backend Developer',
  //   link: '/',

  //   users: [
  //     // 'https://assets.maccarianagency.com/avatars/img1.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img2.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img3.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img4.jpg',
  //     // 'https://assets.maccarianagency.com/avatars/img5.jpg',
  //   ],
  // },
  {
    price: 'Free Internship',
    media: uiux,
    title: 'UI/UX',
    tutor: 'Become a UI/UX Designer',
    link: '/uiux',

    users: [
      // 'https://assets.maccarianagency.com/avatars/img1.jpg',
      // 'https://assets.maccarianagency.com/avatars/img2.jpg',
      // 'https://assets.maccarianagency.com/avatars/img3.jpg',
      // 'https://assets.maccarianagency.com/avatars/img4.jpg',
      // 'https://assets.maccarianagency.com/avatars/img5.jpg',
    ],
  },
];

const Spaces = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: isMd ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box>
      <Box maxWidth={{ xs: 420, sm: 620, md: 1 }} margin={'0 auto'}>
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  borderRadius={3}
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ backgroundImage: 'none' }}
                >
                  <CardMedia
                    title={item.title}
                    image={item.media}
                    sx={{
                      position: 'relative',
                      height: { xs: 240, sm: 340, md: 280 },
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      component={'svg'}
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 1921 273"
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                    >
                      <polygon
                        fill={theme.palette.background.paper}
                        points="0,273 1921,273 1921,0 "
                      />
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      position={'absolute'}
                      bottom={0}
                      padding={2}
                      width={1}
                      zIndex={2}
                    >
                      <Box
                        padding={1}
                        bgcolor={'background.paper'}
                        boxShadow={1}
                        borderRadius={2}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          {item.price}
                        </Typography>
                      </Box>
                      <Button
                        variant={'contained'}
                        color="primary"
                        size="large"
                        startIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 19 19"
                            stroke="currentColor"
                            width={16}
                            height={16}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M10.25,2.375c-4.212,0-7.625,3.413-7.625,7.625s3.413,7.625,7.625,7.625s7.625-3.413,7.625-7.625S14.462,2.375,10.25,2.375M10.651,16.811v-0.403c0-0.221-0.181-0.401-0.401-0.401s-0.401,0.181-0.401,0.401v0.403c-3.443-0.201-6.208-2.966-6.409-6.409h0.404c0.22,0,0.401-0.181,0.401-0.401S4.063,9.599,3.843,9.599H3.439C3.64,6.155,6.405,3.391,9.849,3.19v0.403c0,0.22,0.181,0.401,0.401,0.401s0.401-0.181,0.401-0.401V3.19c3.443,0.201,6.208,2.965,6.409,6.409h-0.404c-0.22,0-0.4,0.181-0.4,0.401s0.181,0.401,0.4,0.401h0.404C16.859,13.845,14.095,16.609,10.651,16.811 M12.662,12.412c-0.156,0.156-0.409,0.159-0.568,0l-2.127-2.129C9.986,10.302,9.849,10.192,9.849,10V5.184c0-0.221,0.181-0.401,0.401-0.401s0.401,0.181,0.401,0.401v4.651l2.011,2.008C12.818,12.001,12.818,12.256,12.662,12.412"
                            />
                          </Box>
                        }
                      >
                        2 Months
                      </Button>
                    </Box>
                  </CardMedia>
                  <CardContent>
                    <Typography
                      component={Link}
                      to={item.link}
                      variant={'h6'}
                      gutterBottom
                      align={'left'}
                      sx={{
                        fontWeight: 700,
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      marginY={2}
                      justifyContent={'space-between'}
                    >
                      {/* <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                        marginRight={1}
                      >
                        <path d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                        />
                      </Box> */}
                      <Typography variant={'subtitle1'} color="text.secondary">
                        {item.tutor}
                      </Typography>
                      <Box display={'flex'} alignItems={'center'}>
                        <Box
                          component={'svg'}
                          width={20}
                          height={20}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          color={colors.yellow[700]}
                          marginRight={1}
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </Box>
                        <Typography sx={{ fontWeight: 700 }}>5.0</Typography>
                      </Box>
                    </Box>
                    <Box
                      marginTop={2}
                      display={'flex'}
                      justifyContent={'space-between'}
                    >
                      <AvatarGroup max={4}>
                        {item.users.map((u) => (
                          <Avatar key={u} src={u} />
                        ))}
                      </AvatarGroup>
                    </Box>
                  </CardContent>
                  <Box flexGrow={1} />
                  {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button>Learn More</Button>
                  </CardActions> */}
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Spaces;
