import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import devops from 'assets/courses/devops.jpeg';
import { Link } from 'react-router-dom';

const PopupBox = ({ onClose, open }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={'lg'}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 2,
        },
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} component={Link} to="/devops">
          <Box
            component={'img'}
            src={devops}
            sx={{
              // cursor: 'pointer',
              width: 1,
              height: 1,
              objectFit: 'cover',
              maxHeight: { xs: 300, sm: 280, md: 1 },
            }}
          />
        </Grid>

        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {/* <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingY: { xs: 4, md: 10 },
              paddingX: { xs: 4, md: 12 },
            }}
          >
            <Typography
              variant={'h5'}
              fontWeight={700}
              align={'center'}
              marginBottom={4}
              color={'primary'}
            >
              T H E F R O N T
            </Typography>
            <Typography
              variant={'h3'}
              fontWeight={700}
              align={'center'}
              marginBottom={1}
            >
              Save Big on Your Next Order
            </Typography>
            <Typography align={'center'}>
              Subscribe toour newsletter and{' '}
              <Typography component={'span'} fontWeight={700}>
                get 20% OFF for your first order
              </Typography>
            </Typography>
            <Grid container spacing={2} sx={{ marginY: 4 }}>
              <Grid item container xs={12} justifyContent={'center'}>
                <TextField
                  label="Enter your email"
                  variant="outlined"
                  name={'email'}
                />
              </Grid>
              <Grid item container xs={12} justifyContent={'center'}>
                <Button
                  size={'large'}
                  variant={'contained'}
                  type={'submit'}
                  sx={{ fontWeight: 700 }}
                >
                  Join now
                </Button>
              </Grid>
            </Grid>
            <Typography
              align={'center'}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={onClose}
            >
              I am not interested
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </Dialog>
  );
};

PopupBox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PopupBox;
