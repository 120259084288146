import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Contact, Hero, Story, Team, WhoWeAre } from './components';

const About = () => {
  return (
    <Main colorInvert={true}>
      <Hero />
      <Container>
        <Story />
      </Container>
      <Container paddingTop={'0 !important'}>
        <WhoWeAre />
      </Container>
      <Container maxWidth={800} paddingY={'0 !important'}>
        <Divider />
      </Container>

      <Box bgcolor={'alternate.main'}>
        <Container>
          <Team />
        </Container>
      </Box>
      <Contact />
    </Main>
  );
};

export default About;
