import React from 'react';
import Box from '@mui/material/Box';
import { Map } from './components';

const Contact = () => {
  return (
    <Box>
      <Map />
    </Box>
  );
};

export default Contact;
