import React from 'react';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Main as MainSection } from './components';
import { Widget } from '@typeform/embed-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const CareerOpening = () => {
  const theme = useTheme();
  return (
    <Main>
      <Container>
        <MainSection />
        <Box marginBottom={2} marginTop={3}>
          <Typography
            variant="h2"
            color="text.primary"
            sx={{ fontWeight: 700, textAlign: 'center' }}
          >
            <Typography
              color={'primary'}
              component={'span'}
              variant={'inherit'}
              sx={{
                background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Apply Now!
            </Typography>
          </Typography>
        </Box>
        <Widget
          id="CByPGMUa"
          style={{ width: '100%', height: 700 }}
          className="my-form"
        />
      </Container>
    </Main>
  );
};

export default CareerOpening;
