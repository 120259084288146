import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';

import logoDark from 'assets/Brand/Bitpoint_Dark.png';
import logoLight from 'assets/Brand/Bitpoint_Light.png';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    learning: bitpointxlearning,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Bitpoint"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={mode === 'light' ? logoDark : logoLight}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'About'} items={landingPages} />
        </Box>
        <Box>
          <NavItem title={'Services'} items={companyPages} />
        </Box>
        <Box>
          <NavItem title={'Career'} items={secondaryPages} />
        </Box>
        <Box>
          <NavItem title={'BitpointX Learning'} items={bitpointxlearning} />
        </Box>
        <Box marginTop={2}>
          <Button
            size={'large'}
            variant="outlined"
            fullWidth
            component="a"
            href="/contact"
          >
            Contact Us
          </Button>
        </Box>
        <Box marginTop={1}>
          {/* <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="https://classroom.bitpointnepal.com/"
          >
            BitpointX Learning
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
